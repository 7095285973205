import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import styled from "styled-components"
import { MDXRenderer } from "gatsby-plugin-mdx"
import SEO from "../components/SEO"

export const query = graphql`
  query($slug: String!) {
    concept: mdx(frontmatter: { slug: { eq: $slug }, type: { eq: "CONCEPT" } }) {
      frontmatter {
        title
      }
      body
    }
  }
`

const conceptTemplate = ({ data }) => {
  const { title } = data.concept.frontmatter
  const { body } = data.concept
  return (
    <Layout>
      <SEO title={title} />
      <Wrapper>
        <div className="container">
          <div className="concept">
            <h1>{title}</h1>
            <div className="concept-body">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
          </div>
        </div>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  .container {
    width: 100vw;
    padding: 0 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        margin-bottom: 0.4em;
      }
    }

    hr {
      border: 0.5px solid var(--primaryColor);
      width: 50%;
      margin: auto;
      margin-top: 2em;
      margin-bottom: 2em;
    }

    h1 {
      text-align: center;
    }

    .more {
      margin-top: 2em;
    }
  }

  .breadcrumb {
    margin: 2em;
    font-family: var(--headerFont);
    list-style-type: none;
    transition: var(--mainTransition);
    letter-spacing: var(--mainSpacing);
    color: var(--mainBlack);

    svg {
      font-size: 0.8rem;
    }
  }

  .concept-body {
    text-align: left;
    font-size: 1.04em;
    margin: 1em auto 0px;
    align-items: flex-start;
    margin: 0 auto;

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;

      li {
        margin-bottom: 0.4em;
      }
    }
  }

  @media screen and (max-width: 500px) {
    .container {
      padding: 0 1em;
    }
  }
`

export default conceptTemplate
